import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import NavbarWrapper from 'common/src/components/Navbar';
import Drawer from 'common/src/components/Drawer';
import Logo from 'common/src/components/UIElements/Logo';
import HamburgMenu from 'common/src/components/HamburgMenu';
import ScrollSpyMenu from 'common/src/components/ScrollSpyMenu';
import { Container } from './navbar.style';


import LogoImage from 'common/src/assets/image/app/logo.png';

import { DrawerContext } from 'common/src/contexts/DrawerContext';

// Default close button for modal
// const CloseModalButton = () => (
//   <Button
//     className="modalCloseBtn"
//     variant="fab"
//     onClick={() => closeModal()}
//     icon={<i className="flaticon-plus-symbol" />}
//   />
// );
// const CloseModalButtonAlt = () => (
//   <Button
//     className="modalCloseBtn alt"
//     variant="fab"
//     onClick={() => closeModal()}
//     icon={<i className="flaticon-plus-symbol" />}
//   />
// );

const Navbar = ({ navbarStyle, logoStyle, buttonStyle }) => {
  const { state, dispatch } = useContext(DrawerContext);
  // Search modal handler
  // const handleSearchModal = () => {
  //   openModal({
  //     config: {
  //       className: 'search-modal',
  //       disableDragging: true,
  //       width: '100%',
  //       height: '100%',
  //       animationFrom: { transform: 'translateY(100px)' }, // react-spring <Spring from={}> props value
  //       animationTo: { transform: 'translateY(0)' }, //  react-spring <Spring to={}> props value
  //       transition: {
  //         mass: 1,
  //         tension: 180,
  //         friction: 26,
  //       },
  //     },
  //     component: SearchPanel,
  //     componentProps: {},
  //     closeComponent: CloseModalButtonAlt,
  //     closeOnClickOutside: false,
  //   });
  // };
  // Authentication modal handler
  // const handleLoginModal = () => {
  //   openModal({
  //     config: {
  //       className: 'login-modal',
  //       disableDragging: true,
  //       width: '100%',
  //       height: '100%',
  //       animationFrom: { transform: 'translateY(100px)' }, // react-spring <Spring from={}> props value
  //       animationTo: { transform: 'translateY(0)' }, //  react-spring <Spring to={}> props value
  //       transition: {
  //         mass: 1,
  //         tension: 180,
  //         friction: 26,
  //       },
  //     },
  //     component: LoginModal,
  //     componentProps: {},
  //     closeComponent: CloseModalButton,
  //     closeOnClickOutside: false,
  //   });
  // };
  // Toggle drawer
  const toggleHandler = () => {
    dispatch({
      type: 'TOGGLE',
    });
  };

  const Data = useStaticQuery(graphql`
    query {
      appJson {
        menuItems {
          label
          path
          offset
        }
      }
    }
  `);

 const url = typeof window !== 'undefined' ? new URL(window.location.href) : new URL('http://0.0.0.0');

  return (
    
    <NavbarWrapper {...navbarStyle}>
      <Container>
        <Logo
          href="/"
          logoSrc={LogoImage}
          title="LuzIA"
          logoStyle={logoStyle}
        />
         
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {/* <Button
            variant="textButton"
            onClick={handleSearchModal}
            icon={<i className="flaticon-magnifying-glass" />}
            aria-label="search"
          />
          <Button
            variant="textButton"
            onClick={handleLoginModal}
            icon={<i className="flaticon-user" />}
            aria-label="login"
          /> */}
          <Drawer
            width="420px"
            placement="right"
            drawerHandler={<HamburgMenu />}
            open={state.isOpen}
            toggleHandler={toggleHandler} 
            style={{ display: !url.pathname.includes('privacidad')? 'block': 'none' }}
          >
            <ScrollSpyMenu
              menuItems={Data.appJson.menuItems}
              drawerClose={true}
              offset={-100}
            />
          </Drawer>
        </div>
      </Container>
    </NavbarWrapper>
  );
};

// Navbar style props
Navbar.propTypes = {
  navbarStyle: PropTypes.object,
  logoStyle: PropTypes.object,
  buttonStyle: PropTypes.object,
  wrapperStyle2: PropTypes.object,
};

Navbar.defaultProps = {
  navbarStyle: {
    minHeight: '70px',
  },
  logoStyle: {
    width: ['140px', '180px'],
  },
  buttonStyle: {
    minHeight: '70px',
    color: '#fff',
  },
};

export default Navbar;
